import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';

const Faq = () => {
  var pageTitle = 'FAQ';

  return (
    <Layout
      slug="faq"
      title={pageTitle}
      description="Have questions about your pets stay with us? Learn more here!"
    >
      <h1>{pageTitle}</h1>

      <section className="mb-4">
        <h2>Boarding </h2>
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What do I bring for my pet?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                All we need you to bring is your pets usual food and an up to
                date copy of their vaccine records. We are able to provide
                everything else for you however you are welcome to bring any
                toys, treats, bedding or anything else you feel will help your
                pet settle in.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What vaccines does my dog need?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>Rabies, Distemper, Parvovirus, Hepatitis, Parainfluenza</p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What vaccines does my cat need?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Rabies,Feline Rhinotracheitis, Calicivirus and Feline
                Panleukopenia
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What will my pets day look like?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                As all pets are different we try and arrange a schedule to best
                fit them. For young active dogs this will include more time in
                our outside play yards. Playful dogs who are in our social
                programs will have play time and for older or less active pets
                they hang out with our staff in slower paced activity time.
                After we meet your pet at drop off we will be able to give you a
                better idea of what your individual pets day will look like.
                There is a spot on our form where you can list any information
                you would like us to know so if your pet has any activity they
                particularly enjoy that is a great place to list it!
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Can I have updates on my pet during their stay?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes! While we do not automatically provide updates we are more
                than happy to provide updates and pictures of your pet during
                their stay for no extra charge!
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Can you feed my pets separately from each other?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, if you have multiple pets in the same kennel we are able to
                feed them separately for no extra charge. Please note that
                unless pets are from the same family and in the same kennel they
                will always be fed alone.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      My pet requires medication, are you able to administer
                      this?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, we are able to administer medications and accommodate extra
                health requirements. We do ask that you discuss this with us
                before hand and bring extra medication incase you are delayed
                returning. We are able to administer most medications free of
                charge.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      My pet is scared of thunder, will they be alone during
                      storms?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                No, we live on property and check on all pets at the beginning
                signs of a storm. If your pet is nervous about storms mention
                this at drop off on their forms and we will discuss the best
                ways to alleviate their anxiety. If needed we will stay with
                your pet for the duration of the storm and have even slept in
                the kennel on multiple occasions!
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do my pets have access to the outdoors?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes! Your pet will have access to a secure, private outdoor area
                at all times except during extreme weather circumstances. Even
                cats have have an optional, fully enclosed, outdoor area.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      My pet does not get along well with others, can they have
                      a private area?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes! Unless otherwise requested all pets will have their own
                private indoor/outdoor area and will not be socialized. For
                social pets we have an optional play and stay program available
                for an additional fee.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do you have discounts for extended stays?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes! We offer weekly and monthly rates. Please contact us for
                more information if it is for a medical or work related stay.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What is your cancellation policy?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Our cancellation policy is flexible during the off season so
                please inquire. During the summer and on holidays we have a 1
                week cancellation policy. We require a $50 deposit to confirm a
                reservation at this time. It is completely refundable if the
                cancellation is made at least one week before the start of the
                reservation.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What do you take for payment?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We can take cash, cheques or etransfer. Our email for Etransfer
                is laura.vipets@yahoo.ca
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>

      <section>
        <h2>Grooming</h2>
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do you have any breed restrictions?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>No, we accept all breeds and sizes.</p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do you groom cats?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, all of our grooming staff is trained in cat grooming. In
                addition Tiana is also a certified cat groomer.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What are your prices?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Prices vary based on the breed and the groom. Please check out
                our grooming rates page for a general idea of our pricing. We
                ask that you keep in mind these are an estimate, for a specific
                quote please feel free to contact us.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do you express anal glands?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, we can express anal glands however this is not included in
                our typial grooms and is an add on service.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do I have to book and appointment in advance?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We do offer drop in nail trims however our grooming services are
                by appointment. Ocassionally we can fit in drop in groomings
                appointments however this is rare.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Will my pet be kenneled during the grooming process and
                      are they ever out with other animals?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We do offer a private kennel free service however during a
                standard groom pets will likely be kenneled for short periods of
                time. If kenneling can be an issue for your pet please discuss
                this with us when booking an appointment and we will work with
                you to figure out the best way to accommodate your pet! At no
                point during the groom will your pet be allowed out with others.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      When should I bring my puppy in for their first groom?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The sooner the better! We recommend bringing them in after they
                receive their puppy vaccines. We offer a puppy intro groom
                specifically designed for puppies under 5 months to help get
                them used to the grooming process as well as the sounds and
                smells in the grooming salon without being overwhelmed. This
                also gives them a great opportunity to meet their grooming and
                become more comfortable with them.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      How long does the grooming process take?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The time can vary greatly depending on the dog as well as the
                groom. For a standard groom on a small dog (Shih-tzu, Maltese,
                Miniature Poodle etc) it typically takes about two hours.
                Services such as nail trims however only take approximately 10
                minutes. For more information about the how long your pet will
                take please contact us!
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      How often should I bring my pet in?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                This can vary depending on the dog and the type of hair cut you
                want. For most breeds bringing we recommend bringing them in
                every 3 months. For longer haircuts or breeds that require a
                little extra maintenance such as poodles or poodle crossed we
                recommend 4-8 weeks.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Will my pet ever be drugged or sedated?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                No, we will never sedate or drug any pets. If your pet has
                adverse reactions to grooming please discuss this with us at
                booking. If we feel your pet is too upset about the process we
                will stop the groom for their safety and work with you on
                counter conditioning this response. If you feel your pet needs
                sedation please talk to your vet.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Do you have flea and tick treatments?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, we can provide flea and tick treatments however we charge
                an additional fee when this is necessary as we try to prevent
                bringing parasites into our facilities. We can not provide
                preventatives and recommend talking to your vet.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What is your cancellation policy?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Since we operate by appointment we ask that you give us 48hrs
                notice if you have to cancel your appointment for any reason. If
                you do not cancel with 24hrs notice and we are able to fill the
                appointment there will be no cancellation fee applied. If we are
                unable to fill the appointment due to the short notice there
                will be a 50% fee applied to your next appointment with us.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      Can I stay during my pets groom?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We do not typically recommend this as this often causes them to
                be extra wiggly during their groom since they are excited to see
                you, however; depending on your pets needs we can usually
                accommodate having you stay with them.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      I loved my pets haircut, what can I do?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We are so happy you loved your pets cut! At the time of payment
                you can always tip your groomer if you feel they did a good job,
                similar to your own hairdresser. You can also leave us a review
                on facebook or google to let others know about your experience
                with us and what you enjoyed about it! We will also make note
                about your pets cut so that we are able to replicate it in the
                future!
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      My pet is matted, will you shave them?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Depending on the condition they are in this may be the only
                option. Since brushing out mats does pull at the skin it can be
                painful for your pet. While some brushing is appropriate we will
                not brush excessively as we feel it is inhumane. If this is an
                issue your groomer will discuss this with you before shaving
                your pet. If you choose to take them home and work on de-matting
                over the course of several days we are always happy to rebook
                the appointment.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {state => (
                    <AccordionItemIcon state={state}>
                      What if I'm not happy with my pets haircut?
                    </AccordionItemIcon>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We always try our best however sometimes there are
                miscommunications about your expectations from the groom. If you
                would like something different with your pets haircut please let
                us know! In some cases we are able to modify the groom right
                then and if not we will offer you a complimentary touch up
                within the following week. We will also make detailed note of
                what you want done differently so that we have this for future
                reference.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>
    </Layout>
  );
};

export default Faq;

const AccordionItemIcon = ({ children, state }) => {
  return (
    <span>
      <FontAwesomeIcon
        icon={state.expanded ? faChevronDown : faChevronRight}
        className="mr-3"
      />
      {children}
    </span>
  );
};
